<template>
<div class="" id="TechFair">
  <Preloader v-if="!loaded" />
  <div class="container-fluid">
    <div class="row p-2">
      <div class="col-md-10 offset-md-1 col-sm-12" style="text-align: justify !important;" v-html="pageContent"></div>
    </div>
  </div>
</div>
</template>

<script>
import Preloader from "../components/Preloader";
export default {
  name: "TechFair",
  components:{
    Preloader,
  },
  data(){
    return{
      loaded:false,
      pageContent:null
    }
  },
  created() {
    this.$http.get("tech-fair").then(function (response) {
      this.pageContent = response.body.content;
      // this.loaded=true;
      this.loaded=true;
      // console.log(response.body.content);
    })
  },
}
</script>

<style scoped>

</style>
